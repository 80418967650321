@import "../../../styles/mixins";
@import "../../../styles/variables";

.footer {
   padding: 40px 40px 0 40px;
   background-color: $light-primary-black-100;
   color: white;
   margin-top: auto;

   &__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_info {
         display: flex;
         justify-content: space-between;
         align-items: flex-end;
      }
   }

   &__logo {
      height: 20px;
      margin-right: 20px;
      width: 90px;

      img {
         height: 20px;
         width: 100%;
      }
   }

   &__email {
      display: flex;
      align-items: center;
      height: 20px;

      img {
         padding-right: 10px;
      }
   }

   &_links_list {
      ul {
         display: flex;
         height: 100%;
         align-items: flex-end;

         li {
            font-family: $default-font;
            padding: 0 15px;
            font-weight: 500;
            font-size: 15px;

            a {
               color: white;
            }

            &:last-child {
               padding-right: 0;
            }
         }
      }
   }
}

.container {
   max-width: 1000px;
   margin: 0 auto;
}

.footer-bottom {
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 12px;
   line-height: 30px;
   color: #ffffff;
   opacity: 0.8;
   padding: 30px 0 25px 0;
}

.copyright {
}
.links {
   a {
      color: inherit;
      opacity: inherit;
      &:hover {
         text-decoration: underline;
      }
   }
}

@media (max-width: 901px) {
   .footer {
      flex-direction: column;

      &__nav {
         align-items: center;
         flex-direction: column;
      }
   }

   .footer__nav_info {
      flex-direction: column;
      align-items: center;
      padding: 0 0 30px 0;
   }

   .footer__email {
      margin: 20px 0 0 0;
   }

   .footer_links_list {
      ul {
         display: none;
      }
   }

   .footer-bottom {
      flex-direction: column;

      *:not(:last-child) {
         padding-bottom: 20px;
      }
   }
}

@media (max-width: 500px) {
   .footer__email {
      padding-bottom: 30px;
      margin: 40px 0 0 0;
   }

   .footer__nav_info {
      width: 300px;
   }
}

.link {
   color: white;
}

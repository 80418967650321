@import '../../styles/variables';

.btn {
    --color-primary: #1C1A1D;
    --color-accent: #EDFF00;
    font-family: $default-font;
    min-height: 40px;
    font-size: 15px;
    font-weight: 500;
    transition: all 200ms ease-in;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;

  &:focus {
    color: var(--color-primary);
    background-color: white;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--primary {
    color: white;
    background-color: var(--color-primary);

    &:hover:not(:disabled) {
      color: var(--color-primary);
      background-color: var(--color-accent);
    }

    &:disabled {
      color: rgba(255, 255, 255, 0.25);
    }
  }

  &--accent {
    color: var(--color-primary);
    background-color: var(--color-accent);

    &:hover:not(:disabled) {
      color: white;
      background-color: var(--color-primary);
    }

    &:disabled {
      color: rgba(42, 43, 43, 0.35);
    }
  }

  &--loading {

    &:disabled {
      color: var(--color-primary);
      background-color: white;
    }
  }

}


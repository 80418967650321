html, body {
  letter-spacing: 1px;
}

* {
  line-height: 140%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


@import '../../styles/variables';
@import '../../styles/mixins';

.wrapper {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(90deg, $light-primary-black-100 42%, $light-secondary-white-100 42%, $light-secondary-white-100 100%);
  @include md {
    flex-direction: column;
    background: none;
  }
}

.mobileNavigation {
  @include lg {
    display: none;
  }
}

.infoWrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex: 0 0 42%;
  flex-direction: column;
  justify-content: center;
  padding: 0 110px 0 40px;
  color: $light-secondary-white-100;
  @include md {
    flex: auto;
    align-items: center;
    padding: 75px 20px 85px 20px;
    background: linear-gradient(356deg, $light-secondary-white-100 75px, $light-primary-black-100 76px, $light-primary-black-100 100%);
  }

  .logo {
    position: absolute;
    top: 30px;
    img {
      width: 91px;
      height: 18px;
    }
    @include md {
      display: none;
    }
  }

  .info {
    max-width: 290px;
    @include md {
      text-align: center;
    }
  }
  .title {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 19px;
    p {
      margin-bottom: 30px;
    }
    @include md {
      font-size: 14px;
    }
  }
  .title, .text {
  }

  .email {
    color: $light-secondary-white-100;
    font-size: 15px;
    font-weight: 700;
    @include md {
      font-size: 15px;
    }
    &::before {
      content: "";
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 15px;
      width: 18px;
      height: 14px;
      background: url(../../images/footer_images/message.png);
    }
  }
}

.formWrapper {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 40px 0 150px;
  @include md {
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px 0 20px;
  }
  .form {
    width: 100%;
    max-width: 450px;
  }
  .homeWrapper {
    width: 100%;
    position: absolute;
    padding: inherit;
    top: 30px;
    left: 0;
  }
  .home {
    max-width: 450px;
    width: 100%;
    font-size: 17px;
    text-align: right;
    a {
      font-weight: 500;
      color: $light-primary-black-100;
    }
    @include md {
      display: none;
    }
  }
}
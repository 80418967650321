@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.container {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    height: 500px;
    width: 50%;
    position: absolute;
    background-image: url(../../../../images/form_block_images/build_image.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    @include md {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      176deg, transparent, transparent 45%, $light-primary-black-100 45.2%, $light-primary-black-100 100%);
    top: 0;
    z-index: 2;
    @include md {
      background: linear-gradient(
        176deg, transparent 7%, $light-primary-black-100 7.15%, $light-primary-black-100 100%);
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1080px;
  margin: 0 auto;
  padding: 60px 40px 0 40px;
  @include md {
    flex-direction: column;
    align-items: center;
    padding: 90px 20px 0px 20px;
  }
}

.form_block__text {
  position: relative;
  max-width: 260px;
  margin-bottom: 15px;
  @include md {
    margin-bottom: 30px;
    max-width: initial;
  }
}

.form_block__text__wrapper {
  color: white;
  position: relative;
  z-index: 3;
  @include md {
    text-align: center;
  }
}

.form_block__text__wrapper h2 {
  font-family: $default-font;
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 45px;
  @include md {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.form_block__text__wrapper p {
  font-weight: 400;
  font-size: 15px;
  @include md {
    font-size: 14px;
  }
}
       
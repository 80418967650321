
.wrapper {
  width: 100%;
  color: #000;
  font-weight: 400;
}

.input {
  font-size: 15px;
  border: 1px solid rgba(42, 43, 43, 0.65);
  width: 100%;
  height: 40px;
  padding: 0 10px;
  margin: 0;
  border-radius: 5px;

  &::placeholder {
    color: rgba(42, 43, 43, 0.65);
  }

  &:hover {
    border-color: #496690;
  }

  &:focus {
    border-color: #1C1A1D;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(42, 43, 43, 0.25);
    border-color: rgba(42, 43, 43, 0.25);

    &::placeholder {
      color: rgba(42, 43, 43, 0.25);
    }
  }

}

.hint {
  font-size: 10px;
  padding: 0 0 0 10px;
  height: 20px;
}

.typography {
  margin: 0;
  color: inherit;
  letter-spacing: 1px;
  line-height: 140%;

  &--3xl {
    font-size: 34px;
  }

  &--2xl {
    font-size: 28px;
  }

  &--xl {
    font-size: 22px;
  }

  &--l {
    font-size: 19px;
    font-weight: 500;
  }

  &--m {
    font-size: 17px;
  }

  &--s {
    font-size: 15px;
  }

  &--xs {
    font-size: 9px;
  }

  // Weight
  &--extra-light {
    font-weight: 200;
  }

  &--light {
    font-weight: 300;
  }

  &--normal {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }

  &--semi-bold {
    font-weight: 600;
  }

  &--bold {
    font-weight: 700;
  }

  &--extra-bold {
    font-weight: 800;
  }
}

@import '../../../styles/mixins';
@import '../../../styles/variables';

.nav{
   width: 100%;
   height: 60px;
   padding: 0 40px;
   position: fixed;
   background-color: $light-primary-black-100;
   z-index: 999;  
}

.nav__joinButton {
   width: 130px;
   margin-left: 20px;
   font-weight: 700;
}

.nav__wrapper{
   max-width: 1000px;
   margin: 0 auto; 
   height: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   & > * {
      flex: 0 0 auto;
   }
}
.nav__button{
      display: none;
}

.nav__button.active span{
         display: none;
   }

   .nav__button.active::before{
      top: 5px;
	   transform:rotate(45deg);
   }

   .nav__button.active::after{
         bottom: 5px;
         transform:rotate(-45deg);
   }

   .nav__list{
      display: flex;
      align-items: center;
   }

   .nav__list_wrapper {
      display: flex;
   }

   .nav__list li{
      flex: 0 0 auto;
      height: 30px;
      margin: 0 30px;
      font-size: 17px;
      display: flex;
      align-items: center;
      position: relative;
   }
   .nav__list li  a{
      color: white;
   }

      .logo{
         height: 20px;
         width: 90px;
         z-index: 510;
         img{
            width: 100%;
            height: 20px;
         }
      }  


      .last_child{
         width: 180px;
      }

   .selected, .activeLink{
      position: relative;
      &::after{
         content: '';
         width: 100%;
         height: 5px;
         background-color: #EDFF00;
         position: absolute;
         bottom: -5px;
         border-radius: 2px 2px 0 0;
      }
   }


@include md {
   .nav {
      padding: 0 20px;
   }

   .nav__wrapper{
      width: 100%;
   }

   .nav__list_wrapper{
      flex-direction: column;
      z-index: 500;
      background-color: $light-primary-black-100;
      position: absolute;
      top: -2500%;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 0 !important;
		height: 100vh;
      transition: .5s all ease;
      width: 100% !important;
   }


   .nav__list{
      flex-direction: column;
      display: block;
      padding-top: 117px;

      li ~ li {
         margin-top: 30px;
      }
   }

   .nav__list li{
      height: auto;
   }

   .nav__list li a{
     font-size: 17px;
     font-weight: 400;
   }



   .nav__button{
		position: relative;
		z-index: 510;
		display: block;
		height: 12px;
		width: 18px;
      cursor: pointer;
   }

   .nav__joinButton {
      display: none;
   }

   .nav__list_wrapper.active{
      top: 0;
   }
      .nav__button span,
      .nav__button::before,
      .nav__button::after {
      display: block;
		transition: all .3s ease;
		background-color: white;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
      border-radius: 1px;
      }

		.nav__button::before,
		.nav__button::after{
			content: '';
		}      

		.nav__button::before{
			top: 0;
		}

      .nav__button span {
         top: 5px;
      }

		.nav__button::after{
			bottom: 0;
	}

   .activeLink::after{
      display: none;
   } 


   .last_child{
      display: none !important;
   }

   .selected::after{
      display: none;
   }
}


:root {
  --color-primary-black-100: #2a2b2b;
  --color-primary-black-85: rgb(42 43 43 / 85%);
  --color-primary-black-65: rgb(42 43 43 / 65%);
  --color-primary-black-50: rgb(34 34 34 / 50%);
  --color-primary-black-35: rgb(42 43 43 / 35%);
  --color-primary-black-10: rgba(42 43 43 / 10%);
  --color-primary-black-5: rgb(42 43 43 / 5%);
  --color-secondary-white-100: #fff;
  --color-secondary-white-65: rgb(255 255 255 / 65%);
  --color-accent: #edff00;
  --color-dimmed: rgb(102 128 159 / 15%);
  --color-steel: rgb(107 123 142 / 100%);
  --color-green: rgb(66 138 93 / 100%);
  --color-orange: rgb(219 154 55 / 100%);
  --color-red: rgb(197 62 36 / 100%);
  --border-radius-base: 5px;
  --font-family: plusjakartasans, serif;
  --quick-transition: 0.2s;
  --medium-transition: 0.4s;
  --small-border-radius: 5px;
  --toastify-font-family: var(--font-family);
  --toastify-text-color-light: var(--color-primary-black-100);
}
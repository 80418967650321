
.container {
    padding: 90px 40px 0 40px;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    & > *:not(:last-child) {
        margin-bottom: 48px;
    }
}

.link {
    text-decoration: underline;
    a {
        color: inherit;
    }
    margin-bottom: 16px;
}
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.container{
   padding: 0 40px;
   margin-bottom: 80px;
   @include md {
      padding: 0 20px;
      margin-bottom: 65px;
   }
}


.stage{
   display: flex;
   flex-direction: column;
   max-width: 1000px;
   margin: 0 auto;
   &__title{
      font-family: $default-font;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 50px;
   }

   &__subtitle{
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 106px;
      @include md {
         margin-bottom: 65px;
      }
   }

   &__items{
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
   }

   &_item{
      width: 300px;
      text-align: center;
      &__title {
         font-size: 17px;
         @include md {
            font-size: 15px;
         }
      }
      &__subtitle {
         font-size: 15px;
         @include md {
            font-size: 14px;
         }
      }
      &__number {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 46px;
         height: 46px;
         margin: 0 auto;
         margin-bottom: 50px;
         font-size: 28px;
         color: $light-secondary-white-100;
         background-color: $light-primary-black-100;
         border-radius: 50%;
         @include md {
            margin-bottom: 8px;
         }
      }
   }
}

.stage__button{
   align-self: center;
   background-color: $light-primary-black-100;
   &:hover {
      background-color: $light-color-blue;
   }
}

.stage__button{
   width: 130px;
   margin: 0 auto;
   font-weight: 700;
   button{
      margin: 0 auto;   
   }
}

.stage_item__img{
   padding-bottom: 50px;
}

.stage_item__title{
   font-family: $default-font;
   font-weight: 500;
   font-size: 17px;
   margin-bottom: 35px;
}

.stage_item__subtitle {
   font-weight: 500;
}

@media (max-width:901px) {

   .stage{
      padding: 40px 20px 0px 20px;
   }

   .stage__title{
      font-size: 22px;
      margin-top: 0px;
      margin-bottom: 30px;
   }

   .stage__title,
   .stage__subtitle{
      text-align: center;
   }

   .stage__items{
      flex-direction: column;
      align-items: center;
   }

   .stage_item ~ .stage_item {
      margin-top: 20px;
   }

   .stage_item__title {
      margin-bottom: 27px;
   }

   .stage_item__img{
      display: flex;
      justify-content: center;
      padding: 0;
      margin-bottom: 8px;
   }
}



@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.clr {
  flex-basis: 100%;
  @include md {
    display: none;
  }
}

.header {
  background-color: $light-primary-black-100;
  color: white;
  position: relative;
  margin-bottom: 80px;
}

.container {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  padding: 90px 40px 0 40px;
  @include container;
  @include md {
    padding: 90px 20px 50px 20px;
  }
}

.header__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 425px;
  @include md {
    display: none;
  }

  img {
    height: 425px;
    width: auto;
    @include md {
      height: 247px;
    }
  }

  &_mobile {
    display: none;
    margin-bottom: 31px;
    @include md {
      display: block;
      width: 247px;
      img {
        height: 247px;
      }
    }
  }
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  @include md {
    flex-direction: column;
  }
}

.header__text {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  position: relative;
  min-height: 410px;
  max-width: 500px;
  @include md {
    text-align: center;
    align-items: center;
    min-height: auto;
  }

  @include lg {
    & > * {
      margin-top: 50px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .header__title {
    font-family: $default-font;
    font-weight: 900;
    font-size: 34px;
    @include md {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 17px;
    @include md {
      margin-bottom: 48px;
    }
  }

  &__paragraph {
    font-weight: 500;
    font-size: 15px;
  }
}

.header__button {
  width: 130px;
  @include md {
    margin-bottom: 41px;
  }
}

.startNow {
  font-weight: 700;
}
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.form {
  background-color: #fff;
  width: 100%;
  padding: 45px 50px;
  border: 1px solid #1C1A1D;
  border-radius: 10px;

  @include md() {
    padding: 20px;
  }

  fieldset {
    min-inline-size: auto;
  }
}



.form__content p {
  font-weight: 400;
  font-size: 15px;
}

.form__option {
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  margin: 0 0 20px 0;
  grid-gap: 0 20px;

  @include md() {
    height: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 20px;
  }
}

.form__option li {
  display: flex;
  align-items: center;
}

.form__option li p {
  margin: 0 0 0 5px;
  display: inline-block;

}

.form__text_input {
  margin: 0 0 20px 0;
  padding: 0 0 0 20px;
  width: 100%;
  border: 1px solid #1C1A1D;
  height: 50px;
}

.form__text {
  border: 1px solid #1C1A1D;
  width: 100%;
  height: 7.3em;
}

.form__button {
  width: 100%;
  margin: 20px 0 0 0;
  background-color: $light-primary-black-100;
}

.form__button button {
  width: 100%;
  height: 40px;
  background-color: $light-primary-black-100;
  font-weight: 400;
  color: white;
  font-size: 15px;
}

.card {
  flex: 0 0 600px;
  position: relative;
  perspective: 1000px;
  z-index: 3;
  @include md {
    flex: 0 0 auto;
    width: 100%;
  }
}

.card .front,
.card .back {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  backface-visibility: hidden;
}


.card .back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid black;
}

.card .back .img {
  margin-bottom: 20px;
}

.card .back h2 {
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 20px;
}

.card .back p {
  font-weight: 400;
  font-size: 15px;
}


.card.submitted .front {
  transform: rotateY(180deg);
}


.card.submitted .back {
  transform: rotateY(360deg);
}

@include md {
  .form_block__text {
    height: inherit;
  }

  .container::after {
    top: -70%;
  }

  //.form__option {
  //  grid-template-columns: 1fr;
  //  grid-template-rows: 1fr 1fr 1fr 1fr;
  //  height: inherit;
  //  margin-bottom: 10px !important;
  //}
}
   
   
   
.recaptcha-box {
  margin-top: 20px;
}
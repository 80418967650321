@import '../../styles/variables';

.wrapper {
  width: 100%;
  font-weight: 400;
  padding: 5px 5px 5px 0;
}

.radio {

  & + label {
    font-size: 15px;
    font-weight: 400;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
  }

  &:disabled + label {
    cursor: not-allowed;
    color: rgba(42, 43, 43, 0.65);
  }

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 0 solid $light-primary-black-100;
    border-radius: 100%;
    background: #fff;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: white;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    //-webkit-transition: all 0.2s ease;
    //transition: all 0.2s ease;
  }

  &:not(:checked) + label:before {
    border-width: 2px;
  }

  &:checked + label:before {
    border-width: 6px;
  }

  // Hover
  &:not(:checked):hover + label:before {
    border-color: #496691;
  }

  // Disabled
  &:checked:disabled + label:before,
  &:not(:checked):disabled + label:before {
    border-color: rgba(102, 128, 159, 0.15);
  }
  &:not(:checked):disabled + label:before {
    border-color: rgba(102, 128, 159, 0.15);
    border-width: 6px;
  }

  &:checked:disabled + label:after {
    background-color: rgba(34, 34, 34, 0.5);
  }

  &:not(:checked):disabled + label:after {
    background-color: rgba(102, 128, 159, 0.15);
  }

  &:checked:disabled + label,
  &:not(:checked):disabled + label {
    color: rgba(42, 43, 43, 0.65);
  }

}




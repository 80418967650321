@mixin container {
   margin: 0 auto;
   max-width: 1280px;
}

@mixin section {
   padding: 0 11%;
}

@mixin lg {
   @media (min-width: 911px) {
      @content;
   }
}

@mixin md {
   @media (max-width: 910px) {
      @content;
   }
}

@mixin sm {
   @media (max-width: 500px) {
      @content;
   }
}
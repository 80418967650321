$headerPaddingBottom:15%;

// Colors
$light-primary-black-100: #2A2B2B;
$light-primary-black-85: rgba(42, 43, 43, 0.85);
$light-primary-black-65: rgba(42, 43, 43, 0.65);;
$light-primary-black-50: rgba(34, 34, 34, 0.5);;
$light-primary-black-35: rgba(42, 43, 43, 0.35);
$light-primary-black-25: rgba(42, 43, 43, 0.25);
$light-primary-black-5: rgba(42, 43, 43, 0.05);

$light-secondary-white-second: #F5F7FB;
$light-secondary-white-100: #FFFFFF;
$light-secondary-white-65: rgba(255, 255, 255, 0.65);
$light-secondary-white-35: rgba(255, 255, 255, 0.35);

$light-color-red: #C53E24;
$light-color-green: #428A5D;
$light-color-blue: #1C1A1D;
$light-color-hover: #496691;
$light-color-still: #6B7B8E;
$light-color-border: rgba(181, 189, 215, 0.5);
$light-color-dimmed: rgba(102, 128, 159, 0.15);
$light-color-accent: #EDFF00;

// font
$default-font: "PlusJakartaSans";
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.container {
   padding: 0 40px;
   margin-bottom: 150px;
   @include md {
      padding: 0 20px;
      margin-bottom: 40px;
   }
}

.benefits{
   max-width: 1000px;
   margin: 0 auto;
   &__title{
      margin-bottom: 50px;
      font-family: $default-font;
      font-weight: 900;
      font-size: 28px;
      @include md {
         text-align: center;
         font-size: 22px;
         margin: 37px 0 23px;
      }
   }

   &__subtitle{
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 50px;
      @include md {
         text-align: center;
         font-size: 17px;
         margin-bottom: 19px;
      }
   }

   &__images{
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr 1fr; 
      grid-template-rows: 1fr; 
      justify-content: space-between;
      @include md {
         grid-template-rows: 1fr 1fr; 
         grid-template-columns: repeat(2, max-content);
         grid-gap: 20px 15px;
         justify-content: center;
      }

      &_wrapper{
         display: flex;
         justify-content: space-between;  
      }

      &_item{
         justify-self: center;
         text-align: center;
         width: 210px;
         @include md {
            width: 160px;
            padding: 20px 10px;
            border: 1px solid #B5BDD780;
         }
      }

      .item__img{
         max-height: 170px;
         max-width: 170px;
         width: 100%;
         height: 100%;
         margin-bottom: 50px;
         @include md {
            max-height: 80px;
            max-width: 93px;
            margin-bottom: 20px;
         }
      }
      .item__title{
         margin-bottom: 50px;
         font-family: $default-font;
         font-weight: 500;
         font-size: 17px;
         @include md {
            font-size: 15px;
            margin-bottom: 31px;
            min-height: 2.6em;
         }
      }
      .item__subtitle{
         font-weight: 500;
         font-size: 15px;
         @include md {
            font-size: 12px;
            padding: 0;
         }
      }
   }
}


@media (max-width:901px) {



   .item__subtitle{
      padding-bottom: 20px;
   }
}
.backForm{
   display: flex;
   flex-direction: column;
   text-align: center;

   h2{
      padding:25px 2px 20px 0px;
      font-weight: 900;
      font-size: 22px;
   }

   p{
      font-weight: 400;
      font-size: 15px;
   }
}


@media (max-width:500px) {
   .backForm{
      width: 300px;
   }
}
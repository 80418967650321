@import "./vars";
@import './variables';

/*Обнуление*/
*{padding: 0;margin: 0;border: 0;}
*,*:before,*:after{-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}
nav,footer,header,aside{display: block;}
html,body{height:100%;width:100%;font-size:100%;;font-size:14px;-ms-text-size-adjust:100%;-moz-text-size-adjust:100%;-webkit-text-size-adjust:100%;}
input,button,textarea{font-family:inherit;}
input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner{padding:0;border:0;}
a,a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: inherit;}
/*--------------------*/

@font-face {
   font-family: $default-font;
   font-weight: 100;
   src: url(./fonts/PlusJakartaSans-ExtraLight.woff);
}

@font-face {
   font-family: $default-font;
   font-weight: 300;
   src: url(./fonts/PlusJakartaSans-Light.woff);
}

@font-face {
   font-family: $default-font;
   font-weight: 400;
   src: url(./fonts/PlusJakartaSans-Regular.woff);
}

@font-face {
   font-family: $default-font;
   font-weight: 500;
   src: url(./fonts/PlusJakartaSans-Medium.woff);
}

@font-face {
   font-family: $default-font;
   font-weight: 700;
   src: url(./fonts/PlusJakartaSans-Bold.woff);
}

@font-face {
   font-family: $default-font;
   font-weight: 900;
   src: url(./fonts/PlusJakartaSans-ExtraBold.woff);
}

body{
  font-family: $default-font;
  color: $light-primary-black-100;
  &.lock{
    overflow: hidden;
 }
}



